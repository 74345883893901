import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"

const ProjectOnePage = () => (
  <Layout>
    <SEO title="Project One" />
    <div className="container" style={{marginTop:'80px'}}>
      <div className="row">
        <div className="col-md-6" style={{backgroundColor: 'rgba(0, 0, 0, 0.6)'}}>
          <p style={{color: 'white', marginBottom: '10px', opacity: '0.8'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam at erat sed enim vehicula suscipit sit amet nec orci. Curabitur at tellus id nibh pretium varius vitae in est. Etiam non mauris id arcu placerat tincidunt vel ac quam. Praesent bibendum sagittis justo, quis tempus augue laoreet ac. Nulla malesuada nulla non nulla pharetra aliquet. Quisque hendrerit, orci eget pretium vulputate, tellus arcu accumsan erat, at sagittis ante nulla eget est. Suspendisse maximus leo nisi, nec pharetra velit imperdiet nec. Mauris aliquam dapibus nunc, vitae faucibus enim sollicitudin eget. Praesent id lectus rutrum, ornare velit et, dapibus odio. </p>
        </div>
      </div>
    </div>
  </Layout>
)

export default ProjectOnePage
